<template>
  <div class="py-10 max-w-7xl mx-auto sm:px-6 lg:px-8">
    <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">Share Kairo with your friends ❤️</h1>
  </div>
  <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
    <p>
      At Kairo, we rely on our users to spread the word about our product through word of mouth rather than traditional advertising. If you love Kairo and want to help us grow and improve, we would greatly appreciate it if you shared the app with your friends on social media or via email. Every time someone new discovers Kairo through your recommendation, it helps us grow and evolve. We truly value the support of our users and appreciate any effort you can make to share Kairo with others.
    </p>
    <p class="mt-2 mb-2">
      Suggestions for sharing:
    </p>

    <div class="mt-2 mb-2 border rounded-xl shadow-sm p-6 dark:bg-gray-800 dark:border-gray-700" v-for="suggestion in suggestions" v-bind:key="suggestion.id">
      <div class="p-2">
      <blockquote class="relative">
        <svg class="absolute top-0 left-0 transform -translate-x-6 -translate-y-8 h-16 w-16 text-gray-100 dark:text-gray-700" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
          <path d="M7.39762 10.3C7.39762 11.0733 7.14888 11.7 6.6514 12.18C6.15392 12.6333 5.52552 12.86 4.76621 12.86C3.84979 12.86 3.09047 12.5533 2.48825 11.94C1.91222 11.3266 1.62421 10.4467 1.62421 9.29999C1.62421 8.07332 1.96459 6.87332 2.64535 5.69999C3.35231 4.49999 4.33418 3.55332 5.59098 2.85999L6.4943 4.25999C5.81354 4.73999 5.26369 5.27332 4.84476 5.85999C4.45201 6.44666 4.19017 7.12666 4.05926 7.89999C4.29491 7.79332 4.56983 7.73999 4.88403 7.73999C5.61716 7.73999 6.21938 7.97999 6.69067 8.45999C7.16197 8.93999 7.39762 9.55333 7.39762 10.3ZM14.6242 10.3C14.6242 11.0733 14.3755 11.7 13.878 12.18C13.3805 12.6333 12.7521 12.86 11.9928 12.86C11.0764 12.86 10.3171 12.5533 9.71484 11.94C9.13881 11.3266 8.85079 10.4467 8.85079 9.29999C8.85079 8.07332 9.19117 6.87332 9.87194 5.69999C10.5789 4.49999 11.5608 3.55332 12.8176 2.85999L13.7209 4.25999C13.0401 4.73999 12.4903 5.27332 12.0713 5.85999C11.6786 6.44666 11.4168 7.12666 11.2858 7.89999C11.5215 7.79332 11.7964 7.73999 12.1106 7.73999C12.8437 7.73999 13.446 7.97999 13.9173 8.45999C14.3886 8.93999 14.6242 9.55333 14.6242 10.3Z" fill="currentColor"/>
        </svg>

        <div class="relative z-10">
          <p class="text-gray-800 dark:text-white"><em>
            {{ suggestion.text}}
            </em>
          </p>
        </div>
      </blockquote>

      <a type="button" :href="suggestion.twitter" target="_blank" class="mt-2 mr-3 inline-flex items-center rounded-md border border-transparent bg-gray-600 px-3 py-1 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
        <svg viewBox="0 0 20 20" aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor"><path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0 0 20 3.92a8.19 8.19 0 0 1-2.357.646 4.118 4.118 0 0 0 1.804-2.27 8.224 8.224 0 0 1-2.605.996 4.107 4.107 0 0 0-6.993 3.743 11.65 11.65 0 0 1-8.457-4.287 4.106 4.106 0 0 0 1.27 5.477A4.073 4.073 0 0 1 .8 7.713v.052a4.105 4.105 0 0 0 3.292 4.022 4.095 4.095 0 0 1-1.853.07 4.108 4.108 0 0 0 3.834 2.85A8.233 8.233 0 0 1 0 16.407a11.615 11.615 0 0 0 6.29 1.84"></path></svg>
        Share on Twitter
      </a>

      <a type="button" :href="suggestion.facebook" target="_blank" class="mt-2 mr-3 inline-flex items-center rounded-md border border-transparent bg-gray-600 px-3 py-1 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
        <svg viewBox="0 0 24 24" aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3 8h-1.35c-.538 0-.65.221-.65.778v1.222h2l-.209 2h-1.791v7h-3v-7h-2v-2h2v-2.308c0-1.769.931-2.692 3.029-2.692h1.971v3z"/></svg>
        Share on facebook
      </a>

      <a type="button" :href="suggestion.email" target="_blank" class="mt-2 inline-flex items-center rounded-md border border-transparent bg-gray-600 px-3 py-1 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
        <svg class="-ml-0.5 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path d="M3 4a2 2 0 00-2 2v1.161l8.441 4.221a1.25 1.25 0 001.118 0L19 7.162V6a2 2 0 00-2-2H3z" />
          <path d="M19 8.839l-7.77 3.885a2.75 2.75 0 01-2.46 0L1 8.839V14a2 2 0 002 2h14a2 2 0 002-2V8.839z" />
        </svg>
        Share in email
      </a>

      </div>
    </div>

    <button v-show="!added" @click="doneThis" type="button"
      class="mt-4 border-green-600 border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm text-green-600 bg-green-200 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-50"
      style="box-shadow: inset 0px -6px 0px #34D399, inset 0 -7px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5;"
    >
      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
      </svg>
      <span class="font-bold ml-1">I have done this</span>
    </button>
  </div>
  <div v-show="added" class="max-w-7xl mx-auto sm:px-6 lg:px-8 mt-4">
    <div class="rounded-md bg-green-50 p-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <!-- Heroicon name: mini/check-circle -->
          <svg class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3">
          <h3 class="text-sm font-medium text-green-800">Awesome!</h3>
          <div class="mt-2 text-sm text-green-700">
            <p>Really appreciate what you did there! Kairo grows by word of mouth, no spending on ads or any other marketing. Thank you!
              ❤️❤️❤️
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ShareSocial",
    data() {
      return {
        added: false,
        suggestions: [
          {
            id: 1,
            text: "Hey friends! If you're looking for a new time and habit tracking app, I highly recommend Kairo. It's been a game changer for me in terms of staying organized and on top of my daily tasks. Give it a try and let me know what you think! 👉 getkairo.com",
            facebook: "https://www.facebook.com/sharer/sharer.php?u=https://getkairo.com",
            twitter: "https://twitter.com/intent/tweet?url=https://getkairo.com&text=Hey%20friends!%20If%20you're%20looking%20for%20a%20new%20time%20and%20habit%20tracking%20app,%20I%20highly%20recommend%20Kairo.%20It's%20been%20a%20game%20changer%20for%20me%20in%20terms%20of%20staying%20organized%20and%20on%20top%20of%20my%20daily%20tasks.%20Give%20it%20a%20try%20and%20let%20me%20know%20what%20you%20think!%20%F0%9F%91%89%20",
            email: "mailto:info@example.com?&subject=&cc=&bcc=&body=https://getkairo.com%0AHey%20friends!%20If%20you're%20looking%20for%20a%20new%20time%20and%20habit%20tracking%20app,%20I%20highly%20recommend%20Kairo.%20It's%20been%20a%20game%20changer%20for%20me%20in%20terms%20of%20staying%20organized%20and%20on%20top%20of%20my%20daily%20tasks.%20Give%20it%20a%20try%20and%20let%20me%20know%20what%20you%20think!%20%F0%9F%91%89%20getkairo.com"
          },
          {
            id: 2,
            text: "Want to boost your productivity and get a better handle on your daily habits? Check out Kairo - a fantastic time and habit tracking app that I've been using and loving. It's helped me stay on track and make the most of my time. Give it a try! 😊 getkairo.com",
            facebook: "https://www.facebook.com/sharer/sharer.php?u=https://getkairo.com",
            twitter: "https://twitter.com/intent/tweet?url=https://getkairo.com&text=Want%20to%20boost%20your%20productivity%20and%20get%20a%20better%20handle%20on%20your%20daily%20habits?%20Check%20out%20Kairo%20-%20a%20fantastic%20time%20and%20habit%20tracking%20app%20that%20I've%20been%20using%20and%20loving.%20It's%20helped%20me%20stay%20on%20track%20and%20make%20the%20most%20of%20my%20time.%20Give%20it%20a%20try!%20%F0%9F%98%8A%20",
            email: "mailto:info@example.com?&subject=&cc=&bcc=&body=https://getkairo.com%0AWant%20to%20boost%20your%20productivity%20and%20get%20a%20better%20handle%20on%20your%20daily%20habits?%20Check%20out%20Kairo%20-%20a%20fantastic%20time%20and%20habit%20tracking%20app%20that%20I've%20been%20using%20and%20loving.%20It's%20helped%20me%20stay%20on%20track%20and%20make%20the%20most%20of%20my%20time.%20Give%20it%20a%20try!%20%F0%9F%98%8A%20getkairo.com"
          },
          {
            id: 3,
            text: "If you're looking to up your productivity game and get a better handle on your daily habits, I highly recommend giving Kairo a try. This app has been a lifesaver for me in terms of staying organized and on top of my to-do list. Give it a shot! 😊 getkairo.com",
            facebook: "https://www.facebook.com/sharer/sharer.php?u=https://getkairo.com",
            twitter: "https://twitter.com/intent/tweet?url=https://getkairo.com&text=If%20you're%20looking%20to%20up%20your%20productivity%20game%20and%20get%20a%20better%20handle%20on%20your%20daily%20habits,%20I%20highly%20recommend%20giving%20Kairo%20a%20try.%20This%20app%20has%20been%20a%20lifesaver%20for%20me%20in%20terms%20of%20staying%20organized%20and%20on%20top%20of%20my%20to-do%20list.%20Give%20it%20a%20shot!%20%F0%9F%98%8A%20",
            email: "mailto:info@example.com?&subject=&cc=&bcc=&body=https://getkairo.com%0AIf%20you're%20looking%20to%20up%20your%20productivity%20game%20and%20get%20a%20better%20handle%20on%20your%20daily%20habits,%20I%20highly%20recommend%20giving%20Kairo%20a%20try.%20This%20app%20has%20been%20a%20lifesaver%20for%20me%20in%20terms%20of%20staying%20organized%20and%20on%20top%20of%20my%20to-do%20list.%20Give%20it%20a%20shot!%20%F0%9F%98%8A%20getkairo.com"
          }
        ]
      };
    },
    computed: {
    },
    methods: {
      doneThis() {
        window.EngageKit.track('Social Shared');
        this.added = true;
      },
    }
  };
</script>
